<template>
  <!-- <div class="float"> -->
  <!-- <slot name="host"></slot> -->
  <!-- <transition name="float"> -->
  <div
    class="float-panel draggable"
    v-show="visible"
    :style="{ left: x + 'px', width: width + 'px' }"
  >
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
  <!-- </transition> -->
  <!-- </div> -->
</template>

<script>
import Draggabilly from 'draggabilly'
export default {
  created () {
    // window.$(document).mouseup(() => {
    //   window.$(this.$el).off('mousemove')
    // })
  },
  mounted () {
    if (this.draggable) {
      this.dragger = new Draggabilly(document.querySelector('.draggable'), {
        handle: '.header'
      })
    }
  },
  data () {
    return {
      // visible: false,
      dragger: null
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    x: {
      type: Number,
      default: 40
    },
    y: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 400
    },
    width: {
      type: Number,
      default: 250
    },
    draggable: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'top left'
    },
    showArrow: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
  // events: {
  //   'show.floatPanel': function () {
  //     this.visible = true
  //   },
  //   'close.floatPanel': function () {
  //     this.visible = false
  //     this.$emit('closePanel')
  //   }
  // }
}
</script>

<style>
.float {
  display: inline-block;
  position: relative;
}

.float-panel {
  display: block;
  position: absolute;
  top: 140px;
  /*        left: 20px;*/
  min-width: 250px;
  min-height: 200px;
  max-height: 500px;
  margin-top: 5px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  transform-origin: top left;
}
/*.float-panel .header {
      height: 30px;
      line-height: 30px;
      background-color: red;
    }
    .float-panel .header .close-button {
      float: right;
      margin-right: 0;
      font-size: 18px;
      line-height: 16px;
      height: 16px;
      width: 16px;
      margin-top: 4px;
      border-radius: 100%;
      background-color: rgba(250, 250, 250, .5);
    }*/

.float-enter,
.float-leave-active {
  opacity: 0;
  transform: scale(0, 0);
}

.float-enter-active,
.float-leave-active {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
