<template src="@/../../../templates/brand/components/templates/shop/product/ProductDetail.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import productPricing from './ProductPricing.vue'
import multiLocationGrid from './MultilocationUnitGrid.vue'
import countDown from '@/components/Countdown.vue'
import Lightbox from '@/components/Lightbox.vue'
import imageFile from '@/models/FileModel'
import Modal from '@/components/Modal.vue'
import acl from '@/../../../templates/site/UI/app/acl-rules'

// const allowed = ['jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'ppt', 'pptx']

// logic
export default {
  name: 'ProductDetail',
  created () {
    const _profile = _.assign({}, this.user.profile)
    if (!this.user.profile.shoppingcarts) _profile.shoppingcarts = {}
    if (
      this.settings.cart !== 'default' &&
      !_profile.shoppingcarts[this.settings.cart]
    ) {
      _profile.shoppingcarts[this.settings.cart] = {
        items: {},
        details: {},
        label: this.settings.cartLabel
      }
      this.profileUpdate({ user: this.user, profile: _profile })
    }
  },
  // mounted () {
  //   console.info(' ::: mounted', this.product, this.cart)
  //   this.selectedQtys = this.setInitialQtys()
  // },
  beforeRouteEnter (to, from, next) {
    // if (this.product && this.product._id && this.cart) {
    //   this.selectedQtys = this.setInitialQtys()
    // }
    this.$watch('product', () => {
      // this.findRelatedItems()
      this.selectedQtys = this.setInitialQtys()
    })
    this.$watch('update', () => {
      this.selectedVar = null
      this.countSelected()
      // this.findRelatedItems()
      this.selectedQtys = this.setInitialQtys()
    })

    next()
  },
  components: { productPricing, multiLocationGrid, Lightbox, Modal, countDown },
  props: {
    isProgram: {
      type: Boolean,
      default: false
    },
    isCollection: {
      type: Boolean,
      default: false
    },
    section: {
      type: String,
      default: ''
    },
    update: String,
    productSet: Array
  },
  // component data members
  data () {
    return {
      title: '',
      allowUpload: false, // NB: this should be configurable value
      selectedQtys: {},
      // relatedItems: [],
      cartid: '',
      fieldData: {},
      addDealerLogo: false,
      upload: null,
      newPreview: null,
      flash: null,
      attachNew: false,
      selectedVar: null,
      showAddToCart: false,
      showImages: { hero: false, variations: false },
      lightboxVisible: false,
      modalVisible: false,
      modalimg: { url: null, name: null },
      // Agree modal settings
      modalTitle: this.$t('modal.order.title'),
      // modalBody: 'TEST TEST',//this.appconfig.orderAgreement[this.$i18n.locale],
      // modalBody: '',
      modalFlash: '',
      modalActions: [],
      modalButtons: [
        {
          text: this.$t('modal.order.continue'),
          class: 'primary',
          handler: () => true
        },
        {
          text: this.$t('modal.order.cancel'),
          class: 'reject',
          handler: () => false
        }
      ],
      modalCallback: this.modalcb
    }
  },
  computed: {
    ...mapState({
      collectionProducts: ({ Product }) => Product.listFamilies, // products,
      products: ({ Product }) => Product.atomicFamilies, // familyVariations,
      families: ({ Product }) => Product.productFamilies,
      variations: ({ Product }) => Product.productFamily,
      program: ({ Collection }) => Collection.program,
      collection: ({ Collection }) => Collection.collection,
      locations: ({ Location }) => Location.locations,
      user: ({ App }) => App.user,
      product: ({ Product }) => Product.atomicProduct, // || {content: {en: {}, fr: {} }}, //product,
      navigationContext: ({ navigationContext }) => navigationContext,
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    hero () {
      if (_.isEmpty(this.product)) return false
      if (this.product.images.length === 1) return this.product.images[0]
      return (
        _.find(i => i.default, this.product.images) ||
        _.sortBy(i => i.order, this.product.images)[0]
      )
    },
    secondaryImages () {
      if (_.isEmpty(this.product)) return false
      if (this.product.images.length < 2) return false
      return _.map(
        i => {
          if (i._id !== this.hero._id) return i
        },
        _.sortBy(i => i.order, this.product.images)
      )
    },
    relatedItems () {
      return _.filter(
        i =>
          ~this.product.relatedItems.indexOf(
            i.familyKey + i.variationField.key
          ),
        this.collectionProducts
      )
    },
    allowCustomOption () {
      return (
        _.sum(_.map(q => q.qty, _.values(this.selectedQtys))) >= this.minQty
      )
      // return this.product.customizable && this.selectedQtys[this.product.SKU].qty >= this.product.customMinQty;
    },
    collectionconfig () {
      return this.isCollection ? this.collection : this.program
    },
    settings () {
      return this.isCollection
        ? this.collection.settings || {}
        : this.program.settings || {}
    },
    minQty () {
      if (this.settings.minQty && this.settings.collectionMinQty) {
        return this.settings.minQty
      } else {
        return this.product.minQty || this.settings.minQty || 0
      }
    },
    cart () {
      let _cartid
      if (this.settings.cart && this.settings.cart !== 'default') {
        _cartid = this.settings.cart
        return this.user.profile.shoppingcarts[this.settings.cart]
      } else {
        let _cart
        if (this.settings.checkout && this.settings.communityVolume) {
          _cartid = 'preorders'
          _cart = this.user.profile.preorders
        } else if (this.collectionconfig.allowCustom) {
          _cartid = 'customorders'
          _cart = this.user.profile.customorders
        } else {
          _cartid = 'cart'
          _cart = this.user.profile.cart
        }
        this.setCartid(_cartid)
        return _cart
      }
    },
    cartIsEmpty () {
      if (!this.user) return true
      return !_.keys(this.cart.items).length
    },
    canCheckout () {
      // (isProgram && program.settings.checkout) || (isCollection && collection.settings.checkout)
      // collection.settings.minQty with collection.settings.collectionMinQty means use collection min
      // across all cart.items for collection EXCEPT if collection.settings.excludeProductMin
      // will exclude items with product.minQty when set
      if (_.isEmpty(this.cart.items)) return false
      if (!this.settings.checkout) return false
      // const _minQty = this.product.minQty || this.settings.minQty
      if (this.settings.collectionMinQty) {
        if (this.settings.excludeProductMin) {
          const _excludes = this.collectionProducts
            .filter(p => p.minQty)
            .map(p => p.SKU)
          const _exclude = _.sum(
            _.map(
              k => (~_excludes.indexOf(k) ? this.cart.items[k] : 0),
              _.keys(this.cart.items)
            )
          )
          const _include = _.sum(
            _.map(
              k => (!~_excludes.indexOf(k) ? this.cart.items[k] : 0),
              _.keys(this.cart.items)
            )
          )
          return (
            (_include && _include >= this.collection.settings.minQty) ||
            _exclude
          )
        } else {
          return _.sum(_.values(this.cart.items)) >= this.settings.minQty
        }
      } else {
        return (
          this.aggSelectedUnits >= (this.product.minQty || this.settings.minQty || 0)
        )
      }
    },
    aggSelectedUnits () {
      if (!this.product.units) return this.cart.items[this.product.SKU]
      return _.sum(this.product.units.map(u => this.cart.items[u.SKU] || 0))
    },
    cartUserData () {
      if (
        this.aggSelectedUnits &&
        _.values(this.cart.details).some(d => !_.isEmpty(d.data))
      ) {
        return _.reduce(
          (acc, k) => {
            if (this.cart.details[k] && !_.isEmpty(this.cart.details[k].data)) {
              for (const datum of _.keys(this.cart.details[k].data))
                acc[datum] = this.cart.details[k].data[datum]
            }
            return acc
          },
          {},
          _.keys(this.selectedQtys)
        )
      }
      return ''
    },
    returnURL () {
      return (
        this.navigationContext ||
        this.$route.path.slice(0, this.$route.path.indexOf('/product'))
      )
    },
    next () {
      let _index = this.productSet.findIndex(i =>
        i.units
          ? ~i.units.indexOf(this.product.units ? this.product.units[0].SKU : this.product.SKU)
          : i._id === this.product._id
      )
      if (!~_index || _index === this.productSet.length - 1) return {disabled: true}
      let _prod = this.productSet[_index + 1]
      let _prodpath = (_prod.variationField && _prod.variationField.key) || _prod.variationKey
      const _to = {name: this.isCollection ? 'collectionProduct' : 'programProduct', params: {}}
      if (this.isCollection) _to.params.collection = this.$route.params.collection
      else _to.params.program = this.$route.params.program
      _to.params.fkey = _prod.familyKey
      _to.params.vkey = _prodpath
      return _to
    },
    prev () {
      let _index = this.productSet.findIndex(i =>
        i.units
          ? ~i.units.indexOf(this.product.units ? this.product.units[0].SKU : this.product.SKU)
          : i._id === this.product._id
      )
      if (!~_index || _index === 0) return {disabled: true}
      let _prod = this.productSet[_index - 1]
      let _prodpath =(_prod.variationField && _prod.variationField.key) || _prod.variationKey
      const _to = {name: this.isCollection ? 'collectionProduct' : 'programProduct', params: {}}
      if (this.isCollection) _to.params.collection = this.$route.params.collection
      else _to.params.program = this.$route.params.program
      _to.params.fkey = _prod.familyKey
      _to.params.vkey = _prodpath
      return _to
    },
    categoryClass () {
      return this.product.category.map(c => c.url).join(' ')
    },
    customFields () {
      const _fields = {}
      if (this.product.content[this.$i18n.locale].fields) {
        for (const field in this.product.content[this.$i18n.locale].fields) {
          _fields[field] = this.product.content[this.$i18n.locale].fields[field]
        }
      }
      return _fields
    },
    modalBody () {
      if (this.videoModal) return this.videoModal.iframe
      return ''
    },
    videoModal () {
      if (
        this.product.content &&
        _.get('fields.video', this.product.content[this.$i18n.locale])
      ) {
        const youtubeid = this.product.content[this.$i18n.locale].fields.video
          .body
        return {
          iframe: `<iframe width="815" height="516" src="https://www.youtube.com/embed/${youtubeid}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
          image: `http://img.youtube.com/vi/${youtubeid}/mqdefault.jpg`
        }
      }
      return {}
    }
  },
  // component methods
  methods: {
    ...mapActions([
      'getCollections',
      'setCollection',
      'getCollection',
      'getCollectionProducts',
      'getProgramProducts',
      'profileUpdate',
      'programSummaryUpdate',
      'getProductFamily',
      'getCartProducts',
      'getProgram'
    ]),
    init () {
      // pricing child emits to ensure we set qtys after product is available
      this.selectedQtys = this.setInitialQtys()
    },
    setCartid (val) {
      this.cartid = val
    },
    showmodal () {
      console.info(' ::: show modal?')
      this.$emit('show')
    },
    addCartData (evt, key) {
      if (!this.aggSelectedUnits) return
      let _update = false
      const _cart =
        _.get(this.cartid, this.user.profile) ||
        _.get(this.cartid, this.user.profile.shoppingcarts)
      const _skus = _.filter(u => _cart.items[u], _.keys(this.selectedQtys))
      _.each(s => {
        if (this.settings.multilocation) {
          _.each(l => {
            if (!_cart.details[s].locations[l].data)
              _cart.details[s].locations[l].data = {}
            if (
              _cart.details[s].locations[l].data[key] !== this.fieldData[key]
            ) {
              _cart.details[s].locations[l].data[key] = this.fieldData[key]
              _update = true
            }
          }, _.keys(_cart.details[s].locations))
        } else {
          if (!_cart.details[s].data) _cart.details[s].data = {}
          if (this.fieldData[key] !== _cart.details[s].data[key]) {
            _cart.details[s].data[key] = this.fieldData[key]
            _update = true
          }
        }
      }, _skus)

      if (!_update) return
      const _profile = _.assign({}, this.user.profile)
      this.profileUpdate({
        user: this.user,
        profile: _.assign(_profile, { cartid: this.cartid })
      })
        .then(() => {
          this.selectedQtys = this.setInitialQtys()
          this.$emit('saved.cart', this.cartid)
          this.countSelected()
          this.getCartProducts(this.cartid)
        })
        .catch(err => console.log('profileUpdate threw an error', err))
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    showLightbox (img) {
      this.modalimg = img
      this.lightboxVisible = true
      // this.$emit('lightbox.show')
    },
    countSelected () {
      // actually just tracks if an input is different from the stored cart...
      let _changed = false
      _.each(i => {
        if (this.cart.items[i] !== this.selectedQtys[i].qty) {
          _changed = true
        }
      }, _.keys(this.selectedQtys))
      this.showAddToCart =
        !_changed &&
        !_.reduce((sum, i) => sum + i.qty, 0, _.values(this.selectedQtys))
    },
    getModalActions () {
      const _methods = acl['payment.methods'](this.user.role.level)
      let _actions = [
        {
          name: 'agree',
          label: this.$t('modal.order.disclaimer'),
          type: 'checkbox',
          checked: false
        }
      ]
      if (~_methods.indexOf('po')) {
        _actions.push({
          name: 'paymentMethod',
          label: this.$t('modal.order.method'),
          type: 'radio',
          value: [
            {
              label: this.$t('modal.order.po'),
              name: 'paymentMethod',
              value: 'po',
              disable: acl['cc.only'](this.user.role.level),
              checked: true
            },
            {
              label: this.$t('modal.order.cc'),
              name: 'paymentMethod',
              value: 'cc',
              checked: false
            }
          ]
        })
      } else {
        _actions.push({
          name: 'paymentMethod',
          label: '',
          type: 'hidden',
          value: 'cc'
        })
      }
      return _actions
    },
    selectVar (v) {
      this.selectedVar = v
    },
    getItemLink (item) {
      return this.isCollection
        ? item.variationField.key
          ? `/shop/${this.$route.params.collection}/product/${item.familyKey}/${item.variationField.key}`
          : `/shop/${this.$route.params.collection}/product/${item.familyKey}`
        : item.variationField.key
        ? `/programs/${this.$route.params.program}/product/${item.familyKey}/${item.variationField.key}`
        : `/programs/${this.$route.params.program}/product/${item.familyKey}`
    },
    // findRelatedItems () {
    //   let _rel;
    //   if (!_.get('product.relatedItems', this)) {
    //     _rel = false;
    //   } else {
    //     _rel = _.filter(i => _.intersection(this.product.relatedItems, i.units).length, this.collectionProducts);
    //     if (!_rel.length) _rel = _.filter((i) => ~this.product.relatedItems.indexOf(i.familyKey + (i.variationField && i.variationField.key || i.variationKey)), this.collectionProducts);
    //   }

    //   if (_rel.length) {
    //     this.showImages.variations = true;
    //     this.relatedItems = _rel;
    //   } else {
    //     this.showImages.variations = false;
    //     this.relatedItems = [];
    //   }
    // },
    addToCart () {
      const _profile = _.assign({}, this.user.profile)
      const _cart =
        _.get(this.cartid, _profile) ||
        _.get(this.cartid, _profile.shoppingcarts)
      let _update = false
      let _ismultiloc = false

      if (this.settings.multilocation) {
        _ismultiloc = true
      }

      for (const k in this.selectedQtys) {
        if (!this.selectedQtys[k].qty && _cart.items[k]) {
          delete _cart.items[k]
          delete _cart.details[k]
          _update = true
        } else if (
          (!_cart.items[k] && this.selectedQtys[k].qty) ||
          (this.selectedQtys[k].qty &&
            this.selectedQtys[k].qty !== _cart.items[k])
        ) {
          _cart.items[k] = this.selectedQtys[k].qty
          _cart.details[k] = {}
          _cart.details[k].added = Date.now()
          if (_ismultiloc) {
            _cart.details[k].locations = _.pickBy(v => v, this.selectedQtys[k].locations)
            if (_.some(v => v, _.values(this.fieldData))) {
              _.each(l => {
                if (!_cart.details[k].locations[l].data)
                  _cart.details[k].locations[l].data = {}
                _.each(f => {
                  _cart.details[k].locations[l].data[f] = this.fieldData[f]
                }, _.keys(this.fieldData))
              }, _.keys(_cart.details[k].locations))
            }
          } else {
            if (_.some(v => v, _.values(this.fieldData))) {
              if (!_cart.details[k].data) _cart.details[k].data = {}
              _.each(f => {
                _cart.details[k].data[f] = this.fieldData[f]
              }, _.keys(this.fieldData))
            }
          }
          _cart.details[k].type = this.isCollection ? 'collection' : 'program'
          _cart.details[k].url = this.section
          _update = true
        }
      }

      if (_update) {
        this.profileUpdate({
          user: this.user,
          profile: _profile // _.assign(_profile, { cartid: this.cartid })
        })
          .then(() => {
            this.selectedQtys = this.setInitialQtys()
            this.$emit('saved.cart', this.cartid)
            this.countSelected()
            this.getCartProducts(this.cartid)
          })
          .catch(err => console.log('profileUpdate threw an error', err))
      }
      return true
    },
    addPreorder () {
      const _profile = _.assign({}, this.user.profile)
      const _cart =
        _.get(this.cartid, _profile) ||
        _.get(this.cartid, _profile.shoppingcarts)
      let _updateRequired = false
      for (const k in this.selectedQtys) {
        if (
          (!_cart.items[k] && this.selectedQtys[k].qty) ||
          (this.selectedQtys[k].qty &&
            this.selectedQtys[k].qty !== _cart.items[k])
        ) {
          _cart.items[k] = this.selectedQtys[k].qty
          _cart.details[k] = { program: this.section }

          _updateRequired = true
        }
      }

      if (_updateRequired) {
        this.profileUpdate({ user: this.user, profile: _profile })
        this.programSummaryUpdate({
          program: this.program,
          product: this.product,
          user: this.user,
          profile: _profile
        })
        this.selectedQtys = this.setInitialQtys()
        this.$emit('saved.cart', this.cartid)
        this.countSelected()
        // this.getCartProducts();
      }
      return true
    },
    customize (evt) {
      console.info(' ::: customize', evt)
      console.info('customize', evt)
      const _profile = _.assign({}, this.user.profile)
      const _cart =
        _.get(this.cartid, _profile) ||
        _.get(this.cartid, _profile.shoppingcarts)
      _.each(s => {
        _cart.details[s].customize = evt.target.checked
        _cart.details[s].customUnitPrice = evt.target.checked
          ? typeof this.product.customUnitPrice === 'number'
            ? this.product.customUnitPrice
            : this.program.customUnitPrice || 0
          : 0
      }, _.keys(this.cart.details))
      this.profileUpdate({ user: this.user, profile: _profile })
    },
    setInitialQtys () {
      const _initialvals = {}
      let _ismultiloc = false
      let _locvals = {}

      if (this.settings.multilocation) {
        _ismultiloc = true
        _locvals = this.locations.reduce((acc, i) => {
          acc[i._id] = 0
          return acc
        }, {})
      }
      if (
        !_.isEmpty(this.cart.details) &&
        _.values(this.cart.details)[0].customize
      )
        this.addDealerLogo = true
      if (!this.product.units || !this.product.units.length) {
        _initialvals[this.product.SKU] = { qty: 0 }
        if (this.cart.items[this.product.SKU])
          _initialvals[this.product.SKU].qty = this.cart.items[this.product.SKU]
        if (_ismultiloc)
          _initialvals[this.product.SKU].locations = _.assign({}, _locvals)
        if (this.cart.details[this.product.SKU].locations) {
          _initialvals[this.product.SKU].locations = this.cart.details[
            this.product.SKU
          ].locations
        }
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: 0 }
          if (_ismultiloc) {
            _initialvals[i.SKU].locations = _.assign({}, _locvals)
          }
          // if (_ismultiloc) _initialvals[i.SKU].locations = _.assign({}, _locvals)
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
            if (this.cart.details[i.SKU].locations) {
              _initialvals[i.SKU].locations = _.assign(
                {},
                this.cart.details[i.SKU].locations
              )
            }
          }
        }, this.product.units)
      }

      // if (this.isProgram) this.ensureProfilePreorders();
      // this.showSkus = true;
      return _.assign({}, _initialvals)
    },
    orderAgreement () {
      this.$emit('show')
    },
    modalcb (val, coll, close) {
      if (!val.res) {
        // cancel button
        close()
      } else {
        if (val.agree) {
          const _profile = _.assign({}, this.user.profile)
          _profile.extradata.programAgreement = true
          _profile.extradata.paymentMethod = val.paymentMethod
          this.profileUpdate({ user: this.user, profile: _profile })
          close()
          this.addPreorder()
        } else {
          this.modalFlash = 'You must accept the terms to enter.'
        }
      }
    },
    qtyChange ({ sku, qty, location }) {
      const _qty = parseInt(qty, 10)
      if (location) {
        if (!this.selectedQtys[sku])
          this.selectedQtys[sku] = { qty: 0, locations: {} }

        if (_qty) {
          this.selectedQtys[sku].locations[location] = _qty
        } else if (this.selectedQtys[sku].locations[location]) {
          this.selectedQtys[sku].locations[location] = 0
        }
        this.selectedQtys[sku].qty = _.sum(
          _.values(this.selectedQtys[sku].locations)
        )
      } else {
        this.selectedQtys[sku].qty = _qty
      }
      this.countSelected()
    },
    'lightbox.close' () {
      this.lightboxVisible = false
    },
    'select.file' (file) {
      this.selectedFile = new imageFile(file)
    }
  },
  // locales: {
  //   en: {
  //     title: {
  //       'in-stock-gear': 'In Stock Gear',
  //       uniforms: 'Uniforms',
  //       prebook: 'Prebook'
  //     },
  //     viewBtn: {
  //       'in-stock-gear': 'Get it Now',
  //       uniforms: 'Get it Now',
  //       prebook: 'Get it Now'
  //     }
  //   },
  //   fr: {
  //     title: {
  //       get_it: 'Procurez-vous-le maintenant',
  //       book_it: 'Réservez-le',
  //       customize_it: 'Personnalisez-le'
  //     },
  //     viewBtn: {
  //       get_it: 'Procurez-vous-le maintenant',
  //       book_it: 'Réservez-le',
  //       customize_it: 'Personnalisez-le'
  //     }
  //   }
  // }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/product/ProductDetail.css"></style>
