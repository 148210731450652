<template src="@/../../../templates/brand/components/templates/shop/product/ProductPricing.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
const sizeOrder = [ 'jm', 'jl', 'jx', 'p', 'xs', 's', 'm', 'sm', 'l', 'x', 'xl', 'lxl', 'xxl', '2', '2x', '2xl', 'xxxl', '3', '3x', '3xl', 'xxxxl', '4', '4x', '4xl', 'xxxxxl', '5', '5x', '5xl' ]

export default {
  name: 'ProductPricing',
  created () {
    this.selectedQtys = this.setInitialQtys()
    this.$watch('product', () => {
      this.selectedQtys = this.setInitialQtys()
    })
  },
  props: { cart: Object, settings: Object, product: Object },
  data () {
    return {
      selectedQtys: {},
      showSkus: false // so we don't get ugly errors from template before props arrive...
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    sortedProduct () {
      const mbrs = {}
      let _out = []
      if (!this.product.units || !this.product.units.length)
        return [this.product]
      if (
        (this.product.relatedBy.toLowerCase() === 'size' ||
        this.product.units.some(
          u => String(u.unitField.name).toLowerCase() === 'size'
        )) &&
        this.product.units.some(u => !u.unitField.order)
      ) {
        this.product.units.forEach(
          item =>
            (mbrs[
              (item.unitField && String(item.unitField.key).toLowerCase()) ||
                String(item.size).toLowerCase()
            ] = item._id)
        )
        const _k = _.keys(mbrs)
        const _inter = _.intersection(_k, sizeOrder)
        if (_inter.length === _k.length) {
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.units.find(itm => itm._id === mbrs[item])
            )
          )
        } else if (_inter.length < _k.length) {
          const _diff = _.difference(_k, _inter)
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.units.find(itm => itm._id === mbrs[item])
            )
          )
          _diff.forEach(i => {
            _out.push(this.product.units.find(itm => itm._id === mbrs[i]))
          })
        } else {
          _out = this.product.units
        }
      } else {
        _out = this.product.units
      }
      return _out
    },
    unitMinMax () {
      return _.reduce(
        (acc, i) => {
          acc[i] = { min: 0, max: 0 }
          let _inv = !this.settings.ignoreInventory
            ? this.product.units.find(u => u.SKU === i).inventory
            : 0
          _inv =
            this.product.maxQty && this.product.maxQty > _inv
              ? _inv
              : this.product.maxQty
          acc[i].max = _inv
          acc[i].min =
            this.selectedQtys[i] && this.product.minQty
              ? this.product.minQty
              : 0
          return acc
        },
        {},
        _.keys(this.selectedQtys)
      )
    }
  },
  // component methods
  methods: {
    setInitialQtys () {
      let _initialvals = {}
      !this.product.units || !this.product.units.length
        ? (_initialvals[this.product.SKU] = {
            qty: this.cart.items[this.product.SKU] || 0
          })
        : _.each(i => {
            _initialvals[i.SKU] = { qty: this.cart.items[i.SKU] || 0 }
          }, this.product.units)

      this.$nextTick(() => {
        this.showSkus = true
        // emit event to tell parent skus are available for qtys
        this.$emit('initcomplete')
      })
      return _.assign({}, _initialvals)
    },
    updateItemQtys (evt) {
      let _p
      let _val = parseInt(evt.target.value, 10)
      if (!this.product.units || !this.product.units.length) _p = this.product
      else
        _p = _.find(i => i.SKU === evt.target.dataset.sku, this.product.units)
      let _minQty = _p.minQty
        ? _p.minQty
        : this.product.minQty
        ? this.product.minQty
        : 1
      if (_val === 0) {
        this.$emit('qtychange', {
          _id: evt.target.dataset.id,
          sku: evt.target.dataset.sku,
          qty: _val
        })
        // window.$(evt.target).removeClass('invalid')
        // window.$(evt.target).attr('min', 0)
        // this.selectedQtys[evt.target.dataset.sku].invalid = false
        // this.selectedQtys[evt.target.dataset.sku].min = 0
      } else if (_minQty <= _val) {
        if (!this.settings.ignoreInventory && _val > _p.inventory) {
          _val = _p.inventory
          evt.target.value = _p.inventory
        }
        this.$emit('qtychange', {
          _id: evt.target.dataset.id,
          sku: evt.target.dataset.sku,
          qty: _val
        })
        // window.$(evt.target).addClass('invalid')
        // window.$(evt.target).attr('min', _p)
        // this.selectedQtys[evt.target.dataset.sku].invalid = true
        // this.selectedQtys[evt.target.dataset.sku].min = _p
      } else {
        // window.$(evt.target).addClass('invalid')
        // window.$(evt.target).attr('min', _p)
        // this.selectedQtys[evt.target.dataset.sku].invalid = true
        // this.selectedQtys[evt.target.dataset.sku].min = _p
      }
    },
    priceQty (item) {
      if (!item) return // why does this get called with no value?
      // return item price from adjustments for quantity range of order
      const tier = _.find(
        itm => itm.volume > this.selectedQtys[item.SKU].qty,
        this.product.adjustments
      )
      if (tier) return tier.value
      else if (item.price) return item.price
      else return this.product.price
    },
    inventoryControl (inv) {
      if (!this.settings.ignoreInventory && inv > 0) {
        return inv
      } else {
        return '<i class="fa fa-exclamation-triangle" style="color:#f60;" title="Item is on backorder" aria-hidden="true"></i>'
      }
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    }
  },
  // any custom, imported filters
  filters: {
    priceQty: (val, item) => {
      // if (!item || !item.price) return; // why does this get called with no value?
      // return item price from adjustments for quantity range of order
      const tier = _.find(
        itm => itm.volume > this.selectedQtys[item.SKU].qty,
        this.product.adjustments
      )
      if (tier) return tier.value
      else return val
    }
  },
  // event listeners
  events: {
    'saved.cart' () {
      // this.cart = this.user.profile.cart;
      const _initialvals = {}
      if (!this.product.units || !this.product.units.length) {
        _initialvals[this.product.SKU] = {
          qty: this.cart.items[this.product.SKU]
        }
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: 0 }
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
          }
        }, this.product.units)
      }

      this.selectedQtys = _.assign({}, _initialvals)
    },
    'product.changed' () {
      this.showSkus = false
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/product/ProductPricing.css"></style>
