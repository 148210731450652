import { render, staticRenderFns } from "@/../../../templates/brand/components/templates/shop/product/ProductDetailWrapper.html?vue&type=template&id=6e0a3c0e&"
import script from "./ProductDetailWrapper.vue?vue&type=script&lang=js&"
export * from "./ProductDetailWrapper.vue?vue&type=script&lang=js&"
import style0 from "@/../../../templates/brand/components/styles/shop/product/ProductDetailWrapper.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports